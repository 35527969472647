<template>
   <div>子账户列表</div>
</template>

<script>
export default {
  name: "subAccountList"
}
</script>

<style scoped>

</style>